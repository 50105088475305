let formValidated = false;
jQuery(document).ready(function() {
    if ($('section.portfolio-main .portfolio-wrapper .tabs-wrapper').find(".item:hidden").length == 0) {
        // $("#load-item").fadeOut('slow');
        $(this).find('a.load-more').slideUp();
    }
    $('[href="#"]').attr("href", "javascript:;");
    jQuery('.testi-page .item-box').hide();
    /*
       Load more content with jQuery
   */
    $("section.portfolio-main .portfolio-wrapper .tabs-wrapper .tab-box").hide();
    $("section.portfolio-main .portfolio-wrapper .tabs-wrapper .tab-box:first-child()").slideDown().addClass('active');
    $("section.portfolio-main .portfolio-wrapper .tabs-menu ul li a").click(function(event) {
        event.preventDefault();
        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("data-toggle");
        $("section.portfolio-main .portfolio-wrapper .tabs-wrapper .tab-box").not(tab).slideUp().removeClass('active');
        $('[data-tab="' + tab + '"]').slideDown().addClass('active');
        $('[data-tab="' + tab + '"]').find(".item").slice(0, 3).show();
    });
    $(function() {
        $("section.portfolio-main .portfolio-wrapper .tabs-wrapper .tab-box .item").hide();
        $("section.portfolio-main .portfolio-wrapper .tabs-wrapper .tab-box .item").slice(0, 3).show();
        $("section.portfolio-main .portfolio-wrapper .tabs-wrapper .tab-box .cta-box a.load-more").on('click', function(e) {
            e.preventDefault();
            // alert('s');
            $(this).parent().parent().parent().find(".item:hidden").slice(0, 2).slideDown();
            if ($(this).parent().parent().parent().find(".item:hidden").length == 0) {
                // $("#load-item").fadeOut('slow');
                $(this).slideUp();
            }
            $(window).scrollTop($(window).scrollTop() + 1).scrollTop($(window).scrollTop() - 1);
        });
    });

    $(function() {
        $(".testi-page .item-box").slice(0, 6).show();
        $(".testimonials .cta-btn a").on('click', function(e) {
            e.preventDefault();
            $(".testi-page .item-box:hidden").slice(0, 8).slideDown();
            if ($(".testi-page .item-box:hidden").length == 0) {
                // $("#load-item").fadeOut('slow');
                $(".testimonials .cta-btn a").slideUp();
            }
            $(window).scrollTop($(window).scrollTop() + 1).scrollTop($(window).scrollTop() - 1);
        });
    });
    setTimeout(function() {
        jQuery('.ist-hero-nav-item-spacer + a, .ist-hero-nav-item-spacer + a span, .ist-hero-header h1, .ist-hero-header h2').css({ 'transform': 'translate(0px, 0px)' });

        jQuery('.ist-cursor').insertAfter('.ist-hero-body');
        // console.clear();
    }, 3500);
    jQuery('.home-sect2 .logo-slider').slick({
        rows: 4,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidePerRow: 4,
    });
    jQuery('section.app-slider-sect .wrapper .desc-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: 'section.app-slider-sect .wrapper .app-logo-slider,section.app-slider-sect .wrapper .app-slider'
    });
    jQuery('section.app-slider-sect .wrapper .app-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        infinite: true,
        centerMode: true,
        asNavFor: 'section.app-slider-sect .wrapper .app-logo-slider ,section.app-slider-sect .wrapper .desc-slider'
    });
    jQuery('section.app-slider-sect .wrapper .app-logo-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: 'section.app-slider-sect .wrapper .app-slider ,section.app-slider-sect .wrapper .desc-slider',
        arrows: false,
        dots: false,
    });

    jQuery('.logoslider-row-sect .logo-slider').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
    jQuery('section.web-portfolio-sect,section.ios-app-portfolio').slick({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        
    });
    $('.tab-content').fadeOut();
    setTimeout(function() {
        $('.tab-content:first-child').fadeIn();
        $(".tabs-menu li:first-child").addClass('current')
    }, 10);
    $(".tabs-menu a").click(function(event) {
        event.preventDefault();
        $(this).closest('.tabs-menu').find('li.current').removeClass("current");
        $(this).parent().addClass("current");
        var tab = $(this).attr("data-tab");
        $(tab).parent().find(".tab-content").css("display", "none");
        $(tab).fadeIn();
    });

    $(".web-app-desc .tabs-wrapper .tab-box").hide();
    $(".web-app-desc .tabs-wrapper .tab-box:first-child()").slideDown();
    $(".web-app-desc .tabs-menu ul li a").click(function(event) {
        event.preventDefault();
        $(this).parent().addClass("current");
        $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("data-toggle");
        $(".web-app-desc .tabs-wrapper .tab-box").not(tab).slideUp();
        $('[data-tab="' + tab + '"]').slideDown();
    });
    // $("section.company-profile .tabs-container  .tab-box").hide();
    // $("section.company-profile .tabs-container  .tab-box:first-child()").slideDown();
    // $("section.company-profile .tabs-container  .tabs-menu ul li a").click(function(event) {
    //     event.preventDefault();
    //     $(this).parent().addClass("current");
    //     $(this).parent().siblings().removeClass("current");
    //     var tab = $(this).attr("data-toggle");
    //     $("section.company-profile .tabs-container  .tab-box").not(tab).slideUp();
    //     $('[data-tab="' + tab + '"]').slideDown();
    // });
});

var shrinkHeader = 250;
jQuery(window).scroll(function() {
    var scroll = getCurrentScroll();
    if (scroll >= shrinkHeader) {
        jQuery('nav.nav-bar').addClass('active');
    } else {
        jQuery('.logo-box').removeClass('active');
    }

    function getCurrentScroll() { return window.pageYOffset; }
});



// Home Page Category Section

$('.home-our-services .tabs-menu ul').slick({
    dots: false,
    infinite: true,
    //loop:true,
    speed: 300,
    slidesToShow: 2,
    autoplay: false,
    focusOnSelect: true,
    centerMode: false,
    centerPadding: '0px',
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
});

$('.home-our-services .tabs-menu ul').on('afterChange', function(event, slick, currentSlide, nextSlide) {

    // console.log(currentSlide);
    // console.log(event);

    if ($('.home-our-services .tabs-menu ul li.slick-slide').hasClass('slick-current')) {
        $('.home-our-services .tabs-menu ul li.slick-slide.slick-current').find('a').trigger('click');
        var href = $('.home-our-services .tabs-menu ul li.slick-slide.slick-current').find('a').attr('href');
        // console.log(href);
    }

});
let error = 1;
$(document).ready(function() {
    $(".home-our-services .tabs-wrapper .tab-box:first-child()").slideDown();
    $(".home-our-services .tabs-menu ul li.slick-slide a").click(function(event) {
        event.preventDefault();
        // $(this).parent().addClass("current");
        // $(this).parent().siblings().removeClass("current");
        var tab = $(this).attr("data-toggle");
        $(".home-our-services .tabs-wrapper .tab-box").not(tab).slideUp();
        $('[data-tab="' + tab + '"]').slideDown();
    });
    $('.testimonials .testimonial-slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: false,
        dots: false,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 768,
            settings: {
                adaptiveHeight: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });


    function varchk(tgt, ctry) {
        var formvars = ["cn", "ce", "cpcode", "cp", "su", "sfp"];
        for (var i = 0; i < formvars.length; i++) {

            var stat = jQuery(tgt + ' .jform [name="' + formvars[i] + '"]').attr('name') == formvars[i],
                d = new Date(),
                curr_date = d.getDate(),
                curr_month = d.getMonth() + 1,
                curr_year = d.getFullYear(),
                curdat = curr_month + '/' + curr_date + '/' + curr_year;
            if (!stat) {
                if (formvars[i] == "date") jQuery(tgt + ' .jform').append('<input type="hidden" name="' + formvars[i] + '" value="' + curdat + '" />');
                else if (formvars[i] == "cl") jQuery(tgt + ' .jform').append('<input type="hidden" name="' + formvars[i] + '" value="' + ctry + '" />');
                else if (formvars[i] == "topic") jQuery(tgt + ' .jform').append('<input type="hidden" name="' + formvars[i] + '" value="" />');
                else jQuery(tgt + ' .jform').append('<input type="hidden" name="' + formvars[i] + '" value="1" />')
            }
        }
    }
    jQuery('input[name=pth]').each(function() {
        jQuery(this).val(window.location.href);
    });

    var ctrycode = 1; //------------------------------Change Country Code here if required.

    jQuery.ajax({
        type: "GET",
        url: "/assets/js/countrylist.xml",
        dataType: "xml",
        success: function(xml) {
            jQuery(xml).find('country').each(function() {
                var name = jQuery(this).find('name').text();
                var abbr = jQuery(this).find('abbr').text();
                var code = jQuery(this).find('code').text();
                jQuery('.jform select.countrylist').append('<option value="' + code + '" data-abbr="' + abbr + '">' + name + '</option>');
                jQuery('.jform select.countrylist option[value="' + ctrycode + '"]').attr('selected', 'selected');
                jQuery('.jform input[name="cpcode"]').val('+' + ctrycode);
                $('.jform input[name="acode"]').val('+' + ctrycode);
                jQuery('.jform input[name="ctry"]').val(jQuery('.jform select.countrylist option:selected').html())
            })
        }
    });




    // Code change on select

    jQuery('.jform select.countrylist[name="pc"]').each(function() {
        var id = jQuery(this).parents('.jform').parent().attr('id');
        jQuery(this).bind('change', function() {
            var cval = jQuery(this).children('option:selected').attr('value');
            cnam = jQuery(this).children('option:selected').html();
            console.log(cval);
            jQuery('.jform input[name="cpcode"]').val('+' + cval);
            jQuery('#' + id + ' .jform input[name="ctry"]').val(cnam)
        });


    });

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

     $('form').submit(function(e) {
    e.preventDefault();
    let jformError = 0;
    if ($(this).find('input[name="cn"]').val() == '') {
        $(this).find('#q_full_name').css('border-color', 'red');
        jformError = 1;
        // return false;
    }
    if (!validateEmail($(this).find('input[name="ce"]').val())) {
        $(this).find('#q_email').css('border-color', 'red');
        jformError = 1;
        // return false;
    }
    if ($(this).find('input[name="phn"]').val() == '') {
        $(this).find('#q_phone').css('border-color', 'red');
        jformError = 1;
        // return false;
    }
    if ($(this).find('textarea[name="desc"]').val() == '') {
        $(this).find('textarea[name="desc"]').css('border-color', 'red');
        jformError = 1;
        // return false;
    }
    if (jformError != 1) {

        let form = $(this);
        grecaptcha.ready(function() {
            grecaptcha.execute("6LemPccZAAAAAIFPOPkJUeNBbHcnUlDHaR_akyUN", {
                    action: "submit"
                })
                .then(function(token) {
                    $(form).find('.g-recaptcha-response').val(token);
                    form.unbind('submit').submit();
                    // return true;
                });
        });
    }
});
   //   $('form').submit(function(e) {
   //      e.preventDefault();
   //      let jformError  = 0;
   //      if ($(this).find('input[name="cn"]').val() == '') {
   //      $(this).find('#q_full_name').css('border-color', 'red');
   //      jformError = 1;
   //      // return false;
   //   }
   //  if (!validateEmail($(this).find('input[name="ce"]').val())) {
   //     $(this).find('#q_email').css('border-color', 'red');
   //     jformError = 1;
   //    // return false;
   // }
   //   if ($(this).find('input[name="phn"]').val() == '') {
   //      $(this).find('#q_phone').css('border-color', 'red');
   //      jformError = 1;
   //      // return false;
   //  }
   //  if ($(this).find('textarea[name="desc"]').val() == '') {
   //      $(this).find('textarea[name="desc"]').css('border-color', 'red');
   //      // return false;
   //  }

   //      if (jformError != 1){

   //          let form = $(this);
   //          grecaptcha.ready(function() {
   //              grecaptcha.execute("6LcYfcMZAAAAAHk3OEycb7p7EyKBMPf6RwbnERxT", {
   //                      action: "SignUpForm"
   //                  })
   //                  .then(function(token) {
   //                      $(form).find('.g-recaptcha-response').val(token);
   //                      form.unbind('submit').submit();
   //                      // return true;
   //                  });
   //          });
   //      }
   //  });


    // $('form button').on('click', function(e) {
    //     error = 0;
    //     let form = $(this).closest('form');
    //     if (form.find('#q_full_name').val() == '') {
    //         form.find('#q_full_name').css('border-color', 'red');
    //         error = 1;
    //     }
    //     if (!validateEmail(form.find('#q_email').val())) {
    //         form.find('#q_email').css('border-color', 'red');
    //         error = 1;
    //     }
    //     if (form.find('#q_phone').val() == '') {
    //         form.find('#q_phone').css('border-color', 'red');
    //         error = 1;
    //     }
    //     grecaptcha.ready(function() {
    //         grecaptcha.execute('6LcYfcMZAAAAAHk3OEycb7p7EyKBMPf6RwbnERxT', { action: 'submit' }).then(function(token) {
    //             form.find('[name="recaptcha_response"]').val(token);
    //             if (error == 0) {
    //                 form.submit()
    //             }
    //         });
    //     });
    // });
    // $('form').submit(function(e) {
    //     if (error == 1) {
    //         e.preventDefault();
    //     }
    // });
    $('#q_full_name, #q_email, #q_phone').keyup(function() {
        $(this).css('border-color', '#fff');
    });
    $('.main-box #q_full_name,.main-box  #q_email,.main-box  #q_phone,.popup-form #q_full_name,.popup-form  #q_email,.popup-form  #q_phone').keyup(function() {
        $(this).css('border-color', '#262962');
    });
})
jQuery(window).on('load', function() {
    var url = jQuery(location).attr('href');
    jQuery('input[name="pth"]').val(url);
    jQuery('input[name="su"]').val(url);
})
jQuery(function($) {
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $('ul>li>a').each(function() {
        if (this.href === path) {
            $('header .navbar ul>li>a').removeClass('active');
            $(this).addClass('active');
        }
        if (window.location.href.indexOf("/contact-us/") != -1) {
            $('header .navbar ul>li>a').removeClass('active');
        }
    });

    $('.sub-menu li a').each(function() {
        if (this.href === path) {
            $(this).addClass('active');
            // $('header .navbar ul>li>a').removeClass('active');
            $('.has-child>a').addClass('active');
        }
    });

});

function tmbox() {
    var boxbb = $('section.our-team .team-member').width();
    $('section.our-team .team-member').height(boxbb);
}
$(document).ready(function() {
    $('input[type="tel"]').on('keypress', function(event) {
        var regex = new RegExp("^[0-9]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });
    tmbox();
    $("img").lazyload({
        data_attribute: "orig",
        skip_invisible: false,
        effect: "fadeIn"
    });
})
$(window).resize(function() {
    tmbox();
})

