
jQuery(".work-slider").slick({
	dots: false,
   infinite: true,
   arrows: true,
   //loop:true,
   speed: 300,
   slidesToShow: 4,
   focusOnSelect: true,
   centerMode: false,
   centerPadding: '0px',
   slidesToScroll: 1,
   responsive: [{
		   breakpoint: 1024,
		   settings: {
			   slidesToShow: 2,
			   slidesToScroll: 2,
			   infinite: true,
		   }
	   },
	   {
		   breakpoint: 767,
		   settings: {
			   slidesToShow: 1,
			   slidesToScroll: 1
		   }
	   }
	   // You can unslick at a given breakpoint now by adding:
	   // settings: "unslick"
	   // instead of a settings object
   ]

})
$(".work-brand-slider").slick({
	dots: false,
   infinite: true,
   arrows: true,
   //loop:true,
   speed: 300,
   slidesToShow: 5,
   focusOnSelect: true,
   centerMode: false,
   centerPadding: '0px',
   slidesToScroll: 1,
     responsive: [
     {
      breakpoint: 1074,
      settings: {
        arrows: true,
        
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        arrows:false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]

})
jQuery(".agile-dev-list").slick({
	dots: false,
   infinite: true,
   arrows: false,
   loop:true,
   speed: 300,
   slidesToShow: 2,
   focusOnSelect: true,
   centerMode: false,
   centerPadding: '0px',
   slidesToScroll: 1,
   
   vertical:true,
    autoplay: false,
  autoplaySpeed: 2000,
  verticalSwiping: true,
   responsive: [{
		   breakpoint: 1024,
		   settings: {
			   slidesToShow: 2,
			   slidesToScroll: 2,
			   infinite: true,
		   }
	   },
	   {
		   breakpoint: 767,
		   settings: {
			   slidesToShow: 2,
			   slidesToScroll: 1,
         centerMode: true,
         centerPadding: '8px',

         
		   }
	   }
	   // You can unslick at a given breakpoint now by adding:
	   // settings: "unslick"
	   // instead of a settings object
   ]

})
jQuery(".mb-slider").slick({
	dots: false,
   infinite: true,
   arrows: false,
   loop:true,
   speed: 300,
   slidesToShow: 1,
   focusOnSelect: true,
   centerMode: false,
   centerPadding: '0px',
   slidesToScroll: 1,
   asNavFor: '.mbs-nav-slider',
   fade: true,
  cssEase: 'linear'
  

})
$('.mbs-nav-slider').slick({
  slidesToShow: 2,
  slidesToScroll: 1,
  asNavFor: '.mb-slider',
  dots: false,
  centerMode: false,
  focusOnSelect: true,
  arrows:true,
  responsive: [{
       breakpoint: 1024,
       settings: {
         slidesToShow: 2,
         slidesToScroll: 2,
         infinite: true,
       }
     },
     {
       breakpoint: 767,
       settings: {
         slidesToShow: 2,
         slidesToScroll: 1,
       

         
       }
     }
     // You can unslick at a given breakpoint now by adding:
     // settings: "unslick"
     // instead of a settings object
   ]

});
jQuery(".arena-slider").slick({
	dots: false,
   infinite: true,
   arrows: true,
   //loop:true,
   speed: 300,
   slidesToShow: 4,
   focusOnSelect: true,
   centerMode: false,
   centerPadding: '0px',
   slidesToScroll: 1,
   autoplay: false,
   autoplaySpeed: 1500,
   responsive: [{
       breakpoint: 1024,
       settings: {
         slidesToShow: 3,
         slidesToScroll: 1,
         infinite: true,
       }
     },
     {
       breakpoint: 767,
       settings: {
         slidesToShow: 1,
         slidesToScroll: 1,
       

         
       }
     }
     // You can unslick at a given breakpoint now by adding:
     // settings: "unslick"
     // instead of a settings object
   ]
 

})
jQuery("ul.seo-prof-list").slick({
   dots: false,
   infinite: true,
   arrows: false,
   //loop:true,
   speed: 300,
   slidesToShow: 3,
   focusOnSelect: true,
   centerMode: true,
   centerPadding: '0px',
   slidesToScroll: 1,
     autoplay: false,
   autoplaySpeed: false,
   responsive: [{
         breakpoint: 1024,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
         }
      },
      {
         breakpoint: 767,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1
         }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
   ]

})

   $('[data-targetit]').on('click', function(e) {
      $(this).addClass('current');
      $(this).siblings().removeClass('current');
      var target = $(this).data('targetit');
      $('.' + target).siblings('[class^="box-"]').hide();
      $('.' + target).fadeIn();
    });
   // boxIn Tabbings
     $('[data-targetit]').on('click', function(e) {
      $(this).addClass('current');
      $(this).siblings().removeClass('current');
      var target = $(this).data('targetit');
      $('.' + target).siblings('[class^="boxIn-"]').hide();
      $('.' + target).fadeIn();
    });


   let str = location.href.toLowerCase();
    $("a.nav-link").each(function() {
       if (str.indexOf(this.href.toLowerCase()) > -1) {
          $("ul.navbar-nav li.active_nav").removeClass("active_nav");
          $(this).parent().addClass("active_nav");
       }
   });

   let subStr=location.href.toLowerCase();
    $(".dropdown ul>li>a").each(function() {
       if (subStr.indexOf(this.href.toLowerCase()) > -1) {
          $(".dropdown  ul>li.active_subnav").removeClass("active_subnav");
          $(this).parent().addClass("active_subnav");
           $("li.dropdown-nav > a.nav-link").css("color","#00e06d")
           // main nav anchor
            $("ul.navbar-nav").find("li.active_nav").removeClass("active_nav")

       }

   }); 
    let caseSubStr=location.href.toLowerCase();
    $(".case-dropdown ul>li>a").each(function() {
       if (caseSubStr.indexOf(this.href.toLowerCase()) > -1) {
          $(".case-dropdown  ul>li.active_subnav").removeClass("active_subnav");
          $(this).parent().addClass("active_subnav");
           $("li.case-dropdown-nav > a.nav-link").css("color","#00e06d")
           // main nav anchor
            $("ul.navbar-nav").find("li.active_nav").removeClass("active_nav")

       }

   }); 
    let portStr=location.href.toLowerCase();
    $(".portfolio-dropdown ul li a").each(function() {
       if (portStr.indexOf(this.href.toLowerCase()) > -1) {
          $(".portfolio-dropdown  ul>li.active_subnav").removeClass("active_subnav");
          $(this).parent().addClass("active_subnav");
           $("li.portfolio-dropdown-nav > a.nav-link").css("color","#00e06d")
           // main nav anchor
            $("ul.navbar-nav").find("li.active_nav").removeClass("active_nav")

       }

   }); 

    let indStr=location.href.toLowerCase();
    $(".ind-dropdown ul li a").each(function() {
       if (portStr.indexOf(this.href.toLowerCase()) > -1) {
          $(".ind-dropdown  ul>li.active_subnav").removeClass("active_subnav");
          $(this).parent().addClass("active_subnav");
           $("li.ind-dropdown-nav > a.nav-link").css("color","#00e06d")
           // main nav anchor
            $("ul.navbar-nav").find("li.active_nav").removeClass("active_nav")

       }

   }); 

let mmsubStr=location.href.toLowerCase();
    $("ul.sub-menu>li>a").each(function() {
       if (subStr.indexOf(this.href.toLowerCase()) > -1) {
          $("ul.sub-menu >li.active_subnav").removeClass("active_subnav");
          $(this).parent().addClass("active_subnav");
           // $("li.dropdown-nav > a.nav-link").css("color","#00e06d")
           // main nav anchor
            $("ul.sub-menu").find("li.active_nav").removeClass("active_nav")
            $(this).parent().parent().siblings().css("color","#00e06d")
            $(this).parent().parent().siblings().addClass("hello")
             $(".hello").parent().parent().siblings().css("color","#00e06d")

       }

   }); 


/* RESPONSIVE JS */
if ($(window).width() <= 824) {
 
$(".res__slider").slick({
   dots: false,
   infinite: true,
   arrows: false,
   button: false,
   //loop:true,
   speed: 300,
   slidesToShow: 1,
   focusOnSelect: true,
   centerMode: false,
   centerPadding: '0px',
   slidesToScroll: 1,
     responsive: [
     
    {
      breakpoint: 768,
      settings: {
        arrows:false,
        slidesToShow: 1
      }
    },
    
  ]

})

}

$( document ).ready(function() {
$(".dropdown-nav").hover(function(){
  $(".dropdown  ul li").each(function(index){
    let str = ((index+1) * 40)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
  $(".dropdown h4").each(function(index){
    let str = ((index+1) * 80)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
})
$(".case-dropdown-nav").hover(function(){
  $(".case-dropdown  ul li").each(function(index){
    let str = ((index+1) * 60)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
  $(".case-dropdown h4").each(function(index){
    let str = ((index+1) * 120)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
})
$(".portfolio-dropdown-nav").hover(function(){
  $(".portfolio-dropdown ul li").each(function(index){
    let str = ((index+1) * 60)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
  $(".portfolio-dropdown h4").each(function(index){
    let str = ((index+1) * 120)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
})
$(".ind-dropdown-nav").hover(function(){
  $(".ind-dropdown  ul li").each(function(index){
    let str = ((index+1) * 40)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
  $(".ind-dropdown h4").each(function(index){
    let str = ((index+1) * 80)+"ms"
    $(this).css({"animation-delay": `${str}`})
  })
})
 
});



let observer = new IntersectionObserver((items,self)=>{
       for(let a = 0; a < items.length; a++){
        if(items[a].isIntersecting){

            let src = items[a].target.getAttribute("data-src")
            items[a].target.setAttribute("src",src)

            self.unobserve(items[a].target)
        }
       }
    });
    let images = document.querySelectorAll(".image")
    for( let b = 0; b < images.length; b++){
        observer.observe(images[b])
    }

gsap.registerPlugin(ScrollTrigger);

   


const myFunction = ()=> {
  myVar = setTimeout(showPage, 1200);
}

const showPage =()=> {
  document.querySelector(".preloader").style.display = "none";
}

const myFunction2 = ()=> {
  myVar = setTimeout(showPage, 300);
}


$(".load-more-btn").click( function (e) {
      e.preventDefault();
      $(".onLoad:hidden").slice(0, 3).slideDown();
      if ($(".onLoad:hidden").length == 0) {
        $(".load-more-btn").css('visibility', 'hidden');
      }
      
    });

 let counter = 0


$(".load-more-btn2").click( function (e) {
      e.preventDefault();
      $(".onLoad:hidden").slice(0, 4).slideDown();
      if ($(".onLoad:hidden").length == 0) {
        $(".load-more-btn2").css('visibility', 'hidden');
      }
       
       let imgBg =   $(".onLoad:hidden .rand_col").slice(0, 4)
       let newArr = Array.from(imgBg)
     
     

       for(let i = 0; i < newArr.length; i++){

       let randomColor = '#'+ (`00${newArr[i]}00` + Math.floor(Math.random()*16777215).toString(16)).slice(-6);
       console.log(newArr[i])
       newArr[i].style.backgroundColor = randomColor

       counter = i
       
      
       }
     

     
      })


$(".load-more-btn3").click( function (e) {
      e.preventDefault();
      $(".onLoad:hidden").slice(0, 3).slideDown();
      if ($(".onLoad:hidden").length == 0) {
        $(".load-more-btn3").css('visibility', 'hidden');
      }
      
    });    

  var menu = new MmenuLight(
        document.querySelector( '#menu' ),
        'all'
      );

      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        // theme: 'dark',
        // title: 'Menu'
      });

      var drawer = menu.offcanvas({
        // position: 'left'
      });

      //  Open the menu.
      document.querySelector( 'a[href="#menu"]' )
        .addEventListener( 'click', evnt => {
          evnt.preventDefault();
          drawer.open();
        });


 