jQuery(document).ready(function() {
    setTimeout(function() {
        jQuery('.logo').css('display', 'block')
    }, 100);


// HOME CONCERNED START

    setTimeout(function() {
        jQuery('.home-concerned g#hands').addClass('opacity-1');
    }, 8000);


    setTimeout(function() {
        jQuery('.home-concerned #Plane').addClass('opacity-1');
    }, 8500);

    setTimeout(function() {
        jQuery('.home-concerned #Plane_2').addClass('opacity-1');
    }, 4500);


    setTimeout(function() {
        jQuery('.home-concerned #Plane').addClass('opacity-1');
    },5000);


    setTimeout(function() {
        jQuery('.home-concerned #Cross').addClass('opacity-1');
    },9200);

    setTimeout(function() {
        jQuery('.home-concerned #Cross_2').addClass('opacity-1');
    },9400);

    setTimeout(function() {
        jQuery('.home-concerned #Cross_33').addClass('opacity-1');
    },9600);

// HOME CONCERNED END





        
    // const logo = document.querySelectorAll("#lines path");
    // for(let i = 0; i<logo.length; i++){
    //     console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
    // }
})